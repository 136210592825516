import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class LanguageService {

    get language() {
        // let storageLanguage = localStorage.getItem('language') ?? 'es';
        // if(storageLanguage === 'es'||storageLanguage === 'en'||storageLanguage === 'pt') return storageLanguage;
        return 'es';
    }

    set language(language: 'es'|'en'|'pt') {
        localStorage.setItem('language', language);
    }
}