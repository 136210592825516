import { Component, OnInit } from '@angular/core';
import { ResponseData } from 'src/app/interfaces/data';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'app-footer',
    template: `
        <div class="logo-footer">
            <img class="logo" data-wow-delay="3s" data-wow-duration="3s" data-wow-iteration="infinite" data-wow src="../../assets/images/logo.svg" alt="WCS" height="70">
            <img class="logo" data-wow-delay="3s" data-wow-duration="3s" data-wow-iteration="infinite" data-wow src="../../assets/images/logo1.png" alt="Moore" height="70">
            <img class="logo" data-wow-delay="3s" data-wow-duration="3s" data-wow-iteration="infinite" data-wow src="../../assets/images/logo2.png" alt="Margaret" height="75">
            <!-- <img class="logo" data-wow-delay="3s" data-wow-duration="3s" data-wow-iteration="infinite" data-wow src="{{ data && data.xtras.usaidImage }}" alt="Usaid" height="79">
            <p style="width: 1024px; margin: 2rem auto 0; max-width: 100%">{{ data && data.xtras.footerDescription }}</p> -->
        </div>
    `,
})
export class FooterComponent implements OnInit {

    data?: ResponseData;

    constructor(
        private dataService: DataService,
        languageService: LanguageService,
    ) {
        let language = languageService.language;
        this.dataService.getData(language).subscribe(data => {
            this.data = data ?? undefined;
        });
    }
    
    ngOnInit() {
    }

}