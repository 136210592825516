import { Component } from '@angular/core';
import { NgwWowService } from 'ngx-wow';

import { Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { UserModel } from './models/user-model';
import { DataService } from 'src/app/services/data.service';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import * as $ from 'jquery';
import { ResponseData } from './interfaces/data';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [UserModel]  
})
export class AppComponent {

  darkMode: boolean = true;
  file_path: string = null;
  status_loader: boolean = false;
  global_count: number = 0;
  files_count: number = 0;
  active_popup: boolean = false;
  selected: boolean = false;
  download_count: number = 0;
  download_array = [];
  checkedItems = [];
  public form = [
    { tag: '1. Derechos de los pueblos indígenas', val: '1', isChecked: true },
    { tag: '2. Organización', val: '2', isChecked: false },
    { tag: '3. Planes de Gestión Territorial', val: '3', isChecked: false },
    { tag: '4. Zonificación', val: '4', isChecked: false },
    { tag: '5. Reglamento de acceso y uso de recursos naturales', val: '5', isChecked: false },
    { tag: '6. Cadenas productivas basadas en la naturaleza', val: '6', isChecked: false },
    { tag: '7. Plan de control y vigilancia', val: '7', isChecked: false },
    { tag: '8. Capacitación en administración', val: '8', isChecked: false },
    { tag: '9. Financiamiento sostenible', val: '9', isChecked: false },
    { tag: '10. Monitoreo de gestión territorial indígena', val: '10', isChecked: false },
  ];

  data?: ResponseData;

  constructor(
    public platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private route: Router,
    public userModel: UserModel,
    private dataService: DataService,
    private toastController: ToastController,
    private file: File,
    private fileOpener: FileOpener,
    private fileTransfer: FileTransfer,
    private wowService: NgwWowService,
    languageService: LanguageService, 
  ) {
    let language = languageService.language;
    this.dataService.getData(language).subscribe(data => {
      this.data = data ?? undefined;
    });
    this.wowService.init();
    this.initializeApp();
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.darkMode = prefersDark.matches;
  }

  change() {
    this.darkMode = !this.darkMode;
    document.body.classList.toggle('dark');
  }

  selectall(){
    let input = $('.download_modules .content_download-module').find('input');
    if(this.selected){
      this.selected = false;
      this.checkedItems = [];
      input.prop('checked', false);
    } else {
      this.selected = true;
      this.checkedItems = ["1","2","3","4","5","6","7","8","9","10"];
      input.prop('checked', true);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  clearAllFiles() {
    console.log(this.userModel.count());
    this.userModel.clear();
    console.log(this.userModel.count());
    alert('Se borraron todos los items');
  }

  cancelDownload() {
    this.status_loader = false;
    this.files_count = 0;
  }

  downloadAllFiles(ids) {
    this.status_loader = true;
    this.global_count = 0;
    this.files_count = 1;
    let count = 0;
    this.dataService.getFiles(ids).subscribe((resp) => {
      console.log(resp);
      const urls = resp['data']['files_array'];
      this.files_count = urls.length;
      count = resp['data']['count'];
      // Mostrar loader
      for (let i = 0; i < urls.length; i++) {
        if(this.userModel.checkItem(urls[i].url)){
          this.global_count = this.global_count + 1;
        } else {
          if(this.status_loader){
            this.downloadIdle(urls[i].id, urls[i].name, urls[i].url);
          }
        }
      }
    });
    
  }

  downloadIdle(id, name, url) {
    this.download_array.push({'id':id,'name':name,'url':url});
    this.processDownload();
  }
  
  processDownload() {
    if(this.download_count<20&&this.download_array.length>0){
      this.download_count = this.download_count + 1 ;
      var download_item = this.download_array[0];
      this.download_array.splice(0, 1);
      this.download(download_item['id'],download_item['name'],download_item['url']);
    }
  }

  download(id, name, url) {
    //if(this.platform.is('cordova')) {
      const fileTransfer: FileTransferObject = this.fileTransfer.create();
      fileTransfer.download(url, this.file.dataDirectory + name).then((entry) => {
        this.file_path = entry.toURL();
        console.log('Descargado' + this.file_path+'('+id+' - '+name+' - '+url+')');
        this.userModel.addItem({id:id,file_path:this.file_path,url:url})
        this.showToast(id);
      }, (error) => {
        // handle error
      });
    /*} else {
      console.log('Descargado ('+id+' - '+name+' - '+url+')');
      this.showToast(id);
    }*/
  }

  showToast(id) {
    this.toastController.create({
      message: 'Recurso '+id+' descargado.',
      duration: 300,
      position: 'bottom'
    }).then((obj) => {
      obj.present();
      this.global_count = this.global_count + 1;
      this.download_count = this.download_count - 1;
      this.processDownload();
    });
  }

  openFile(url) {
    console.log(this.userModel.count());
    let file_path = this.userModel.checkItem(url);
    if(file_path){
      this.file.resolveLocalFilesystemUrl(file_path).then(succ => {
        /*const file = await Filesystem.readFile({
          path: photo.filepath,
          directory: Directory.Data,
        });*/
        this.fileOpener.open(file_path, 'application/pdf')
          .then(() => console.log('File is opened'))
          .catch(e => console.log('Error opening file', e));
      }).catch(err => alert('file doesnt exist'));
    } else {
      console.log('El archivo '+url+' no existe.')
    }
  }

  activeLoader(){
    this.status_loader = true;
    setTimeout(() => {
      this.status_loader = false;
    }, 4000);
  }

  initPopUp(val){
    console.log(val);
    this.active_popup = val;
  }

  goToHome() {
    this.route.navigate(['/home']);
  }
  goToIntroducing() {
    this.route.navigate(['/introducing']);
  }
  goToChapter(id) {
    this.route.navigate(['/modulo/'+id]);
  }
  goToAvatar() {
    this.route.navigate(['/recursos']);
  }
  goToContact() {
    this.route.navigate(['/contacto']);
  }

 onChange(item) {
    if(this.checkedItems.includes(item)) {
      this.checkedItems = this.checkedItems.filter((value)=>value!=item);
    } else {
      this.checkedItems.push(item)
    }
  }

 submitForm() {
   this.downloadAllFiles(this.checkedItems);
  }

}
