import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ResponseData } from 'src/app/interfaces/data';
import { UserModel, FileItem } from 'src/app/models/user-model';

import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss'],
})
export class FinishComponent implements OnInit {

  public chapter_id = 1;
  public title = null;

  data?: ResponseData;

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    public userModel: UserModel,
    private menu: MenuController,
    private dataService: DataService, 
    languageService: LanguageService,
  ) {
    let language = languageService.language;
    this.dataService.getData(language).subscribe(data => {
      this.data = data ?? undefined;
    });
  }

  ngOnInit() {
    this.chapter_id= parseInt(this.activeRoute.snapshot.paramMap.get('module_id'));
    this.dataService.getLesson(this.userModel.count(), this.chapter_id)
    .subscribe(resp => {
      console.log(resp);
      this.title = resp['data']['name'];
    })
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

  goBack() {
    this.route.navigate(['/modulo/'+this.chapter_id]);
  }

  goToNextChapter() {
    var next_chapter = this.chapter_id + 1;
    this.route.navigate(['/modulo/'+next_chapter]);
  }
  goToDownloads() {
    this.route.navigate(['/downloads/'+this.chapter_id]);
  }

  openMenu() {
    this.menu.enable(true, 'primerMenu');
    this.menu.open('primerMenu');
  }
}
