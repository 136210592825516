import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from './image/image.component';
import { PreviewPdfComponent } from './preview-pdf/preview-pdf.component';
import { IonicModule } from '@ionic/angular';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    ImageComponent,
    PreviewPdfComponent,
    FooterComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [
    ImageComponent,
    PreviewPdfComponent,
    FooterComponent,
  ]
})
export class ComponentsModule { }
