import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class LanguagePopoverComponent implements OnInit {

    constructor(
        private popoverCtrl: PopoverController,
    ) {
    }
    
    ngOnInit() {
    }

    select(language: string) {
        console.log(language);
        this.popoverCtrl.dismiss(language, 'select');
    }

}