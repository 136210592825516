import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ResponseData } from 'src/app/interfaces/data';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  data?: ResponseData;

  constructor(
    private route: Router,
    private menu: MenuController,
    private dataService: DataService, 
    languageService: LanguageService,
  ) {
    let language = languageService.language;
    this.dataService.getData(language).subscribe(data => {
      this.data = data ?? undefined;
    });
  }

  ngOnInit() {}

  goToHome() {
    this.route.navigate(['/home']);
  }
  goToChapter1() {
    this.route.navigate(['/modulo/1/1']);
  }

  openMenu() {
    this.menu.enable(true, 'primerMenu');
    this.menu.open('primerMenu');
  }
}
