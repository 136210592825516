import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, AlertController, Platform, MenuController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

import { ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { PopupComponent } from '../popup/popup.component';
import { ImageComponent } from 'src/app/components/image/image.component';
import { PreviewPdfComponent } from 'src/app/components/preview-pdf/preview-pdf.component';
import { UserModel, FileItem } from 'src/app/models/user-model';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';  
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import * as $  from 'jquery';
import * as jQuery  from 'jquery';
import { LanguageService } from 'src/app/services/language.service';
import { ResponseData } from 'src/app/interfaces/data';

@Component({
  selector: 'app-chapter1',
  templateUrl: './chapter1.component.html',
  styleUrls: ['./chapter1.component.scss'],
})

export class Chapter1Component implements OnInit {
  
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild('audio') audioDiv: ElementRef;

  public percentage = 0;
  public percentage_nice = 0;
  public chapter_id = 1;
  public slide_id = 1;
  public items: any = [];
  public objetives: any = [];
  public sliders: any = [];
  public slider_blocks: any = [];
  public chapter_slider_blocks: any = [];
  public slider_blocks2: any = [];
  public chapter_slider_blocks2: any = [];
  public slider_blocks3: any = [];
  public chapter_slider_blocks3: any = [];
  public audio = null;
  public hasAudio = false;
  //public accordion_sections: any = [];

  platform = null;
  run = false;
  subtitle = null;
  private fileTransfer: FileTransferObject;  
  private _link : string;

  progressbar = false;

  data?: ResponseData;

  constructor(
    protected _sanitizer: DomSanitizer,
    private _element : ElementRef,
    public alertController: AlertController,
    public modalController: ModalController,
    private route: Router,
    public userModel: UserModel,
    private file: File,
    private transfer: FileTransfer,
    private fileOpener: FileOpener,
    private activeRoute: ActivatedRoute,
    private iab: InAppBrowser,
    private platf: Platform,
    private menu: MenuController,
    private dataService: DataService, 
    languageService: LanguageService,
  ) {
    let language = languageService.language;
    this.dataService.getData(language).subscribe(data => {
      this.data = data ?? undefined;
    });
    this.platform = 'web';
    this.route.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
    };
  }

  /*audioFile(file) {
    this.audio = new Audio();
    this.audio.src = file;
    this.audio.load();
  }*/



  ngAfterViewInit(){
    $(function () {
      $('.container_bg-desktop .content_int').scroll(function() {
        console.log($(this).scrollTop());
        if((Math.round($(this).scrollTop() + $(this).innerHeight()) >= Math.round($(this)[0].scrollHeight))) {
          // console.log('End Scroll');
          $('.scroller_notification').css({'opacity':'0', 'visibility': 'hidden'});
        } else {
          // console.log('Scroll');
          $('.scroller_notification').css({'opacity':'', 'visibility': ''});
        }
      });
    });
  }

  ionViewWillLeave(){
    this.run = false;
    if(this.progressbar) {
      function addClickEvent() {
        $('.footer_progress_inner').find('.progress-bar-module').hide();
      }

      $(function () {
        addClickEvent();
      });
    } else {
      function addClickEvent() {
        $('.footer_progress_inner').find('.progress-bar-module').show();
      }

      $(function () {
        addClickEvent();
      });
    }
    console.log(this.progressbar);
    console.log('ionViewWillLeave');
  }

  ionViewWillEnter(){
    console.log(this.progressbar);
    if(this.progressbar) {
      function addClickEvent() {
        $('.footer_progress_inner').find('.progress-bar-module').hide();
      }

      $(function () {
        addClickEvent();
      });
    } else {
      function addClickEvent() {
        $('.footer_progress_inner').find('.progress-bar-module').show();
      }

      $(function () {
        addClickEvent();
      });
    }
    console.log('ionViewWillEnter');
    /*if(this.route.getCurrentNavigation().extras.state && this.route.getCurrentNavigation().extras.state.slide_id){
      this.slide_id = this.route.getCurrentNavigation().extras.state.slide_id;
      console.log('slide_id 2: '+this.slide_id);
    }*/
  }

  ngOnInit() {
    console.log('ngOnInit');
    this.platform = 'web';
    this.chapter_id= parseInt(this.activeRoute.snapshot.paramMap.get('module_id'));
    this.slide_id= parseInt(this.activeRoute.snapshot.paramMap.get('id'));
    this.subtitle= parseInt(this.activeRoute.snapshot.paramMap.get('subtitle'));
    let progress = this.activeRoute.snapshot.paramMap.get('progressbar');
    // console.log(this.progressbar);
    console.log(progress);
    console.log('slide_id 1: '+this.slide_id);
    this.hasAudio = false;
    this.run = true;
    var slide_id = this.slide_id
    if(this.route.getCurrentNavigation() && this.route.getCurrentNavigation().extras && this.route.getCurrentNavigation().extras.state){
      var items = this.route.getCurrentNavigation().extras.state.items;
      var subtitle = this.route.getCurrentNavigation().extras.state.subtitle;
      //this.dataService.getAudio();
      this.sliders = items;
      this.subtitle = subtitle;
      this.processPage();
    } else {
      this.dataService.getLesson(this.userModel.count(), this.chapter_id)
      .subscribe(resp => {
        console.log(resp);
        //this.dataService.getAudio();
        this.sliders = resp['data']['chapter_sliders'];
        this.subtitle = resp['data']['subname'];
        this.processPage();
      });
    }
    // console.log(this.sliders);
  }

  processPage(){
    if(this.slide_id > this.sliders.length){
      alert('No se encontró el slide que busca')
      this.goToSlide(this.sliders.length)
    }
    if(this.slide_id == 0){
      this.percentage = 0;
    } else if(this.slide_id == this.sliders.length){
      this.percentage = 1;
    } else {
      this.percentage = Math.round(((this.slide_id / this.sliders.length) + Number.EPSILON) * 100) / 100;
    }
    this.percentage_nice = this.percentage * 100;
    var percentage_text = this.percentage_nice.toString();
    this.percentage_nice = parseInt(percentage_text);
    var block_content = this.sliders[this.slide_id];
    this.slider_blocks3 = block_content; 
    this.chapter_slider_blocks3 = block_content.chapter_slider_blocks;
    for (let i = 0; i < this.chapter_slider_blocks3.length; i++) {
      if(this.chapter_slider_blocks3[i]){
        if(this.chapter_slider_blocks3[i].type=='file'&&this.chapter_slider_blocks3[i].file.type=='audio'){
          this.hasAudio = true;
        }
      }
    }
    if(this.platf.is('cordova')){
      this._enableDynamicHyperlinks();
    }
    //this.audio = this.dataService.getTextToAudio(block_content['id']);
    this.dataService.getBtnScrollTable();
    // SCROLL
    setTimeout(function(){ 
      console.log($('.container_bg-desktop .content_int').scrollTop());
      console.log($('.container_bg-desktop .content_int').innerHeight());
      console.log(Math.round($('.container_bg-desktop .content_int')[0].scrollHeight));
      if((Math.round($('.container_bg-desktop .content_int').scrollTop() + $('.container_bg-desktop .content_int').innerHeight()) >= Math.round($('.container_bg-desktop .content_int')[0].scrollHeight))) {
        // console.log('End Scroll');
        $('.scroller_notification').css({'opacity':'0', 'visibility': 'hidden'});
      } else {
        // console.log('Scroll');
        $('.scroller_notification').css({'opacity':'', 'visibility': ''});
      }
    }, 500);
  }

  sanitizeVideo(value){
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }

  goToSlide(slide_id) {
    this.slide_id = slide_id;
    if (this.slide_id == this.sliders.length) {
      this.route.navigate(['/finish/'+this.chapter_id], { state: { items: this.sliders, subtitle: this.subtitle } });
    } else {
      this.route.navigate(['/modulo/'+this.chapter_id+'/' + (this.slide_id)], { state: { items: this.sliders, subtitle: this.subtitle, slide_id: this.slide_id, progressbar: this.progressbar } });
    }
  }

  next() {
    if(this.hasAudio){
      this.audioDiv.nativeElement.pause();
      this.audioDiv.nativeElement.currentTime = 0;
    }
    this.slide_id = this.slide_id + 1;
    if (this.slide_id == this.sliders.length) {
      this.route.navigate(['/finish/'+this.chapter_id], { state: { items: this.sliders, subtitle: this.subtitle } });
    } else {
      this.route.navigate(['/modulo/'+this.chapter_id+'/' + (this.slide_id)], { state: { items: this.sliders, subtitle: this.subtitle, slide_id: this.slide_id } });
    }
  }

  prev() {
    if(this.hasAudio){
      this.audioDiv.nativeElement.pause();
      this.audioDiv.nativeElement.currentTime = 0;
    }
    if( this.slide_id !== 0){
      this.slide_id = this.slide_id - 1;
      this.route.navigate(['/modulo/'+this.chapter_id+'/' + (this.slide_id)], { state: { items: this.sliders, subtitle: this.subtitle, slide_id: this.slide_id } });
    }else {
      this.route.navigate(['/introducing/'+this.chapter_id], { state: { items: this.sliders, subtitle: this.subtitle } });
    }
  }

  async lessonAlert() {
    const alert = await this.alertController.create({
      cssClass: 'lesson-alert',
      header: '¡Consejo!',
      // subHeader: 'Subtitle',
      message: 'Reconocer las bases de la cooperación en el lugar de trabajo.',
      buttons: ['Cancelar', 'Continuar']
    });

    await alert.present();
  }
  async presentModal() {
    const modal = await this.modalController.create({
      component: PopupComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  goToHome() {
    this.route.navigate(['/home']);
  }
  finishChapter() {
    this.route.navigate(['/finish']);
  }

  async viewImage(image){
    if(this.userModel.count()>0){
      console.log('Open Image Offline');
      let new_image = this.userModel.checkItem(image)
      if(new_image){
        let win: any = window;
        image = win.Ionic.WebView.convertFileSrc(new_image);
      }
    }
    console.log(image);
    const modal = await this.modalController.create({
      component: ImageComponent,
      cssClass: 'popup_image',
      componentProps: {
        'image': image
      }
    });
    return await modal.present();
  }

  getOfflineAsset(url){
    if(this.platf.is('cordova')&&this.userModel.count()>0){
      console.log('Get Image Offline');
      let new_image = this.userModel.checkItem(url)
      if(new_image){
        let win: any = window;
        url = win.Ionic.WebView.convertFileSrc(new_image);
      }
    }
    return url;
  }

  async viewPdf(pdf){
    console.log(pdf);
    if(this.platf.is('cordova')){
      this.openBrowser(pdf);
    } else {
      this.openFileComp(pdf);
    }
  }


  async openFileComp(pdf) { 
    const file_modal = await this.modalController.create({
      component: PreviewPdfComponent,
      cssClass: 'popup_image',
      componentProps: {
        'pdf': pdf
      }
    });
    return await file_modal.present();

  }

  openBrowser(url) { 
    if(this.platf.is('cordova')){
      let opened = false;
      if(this.userModel.count()>0){
        console.log('Open Pdf Offline');
        let new_url = this.userModel.checkItem(url);
        if(new_url){
          opened = true;
          this.fileOpener.open(new_url, 'application/pdf').then(() => console.log('File is opened')).catch(e => console.log('Error opening file', e));
        }
      } 
      if(!opened){
        this.iab.create(url, '_system');
      }
    } else {
      window.open(url, '_system');
    }
  }

  playAudio() { 
   this.audio.play();
   this.audio.loop = true;
  }

  stopAudio() {
    this.audio.pause(); 
  }

  ngOnDestroy() {
    if(this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }

  private _enableDynamicHyperlinks() : void{
      // Provide a minor delay to allow the HTML to be rendered and 'found'
      // within the view template
      setTimeout(() => 
      {
         // Query the DOM to find ALL occurrences of the <a> hyperlink tag
         const urls : any    = this._element.nativeElement.querySelectorAll('a');

         // Iterate through these
         urls.forEach((url) => 
         {
            // Listen for a click event on each hyperlink found
            url.addEventListener('click', (event) => 
            {
               // Retrieve the href value from the selected hyperlink
               event.preventDefault();
               this._link = event.target.href;

               // Log values to the console and open the link within the InAppBrowser plugin
               console.log('Name is: ' + event.target.innerText);
               console.log('Link is: ' + this._link);
               this._launchInAppBrowser(this._link);
            }, false);
         });
      }, 2000);
   }

  private _launchInAppBrowser(link : string) : void {
      let opts : string = "location=yes,clearcache=yes,hidespinner=no"
      this.iab.create(link, '_system', opts);
  }
  

  progressBar(val){
    // console.log(val);
    this.progressbar = !val;
    if(this.progressbar) {
      function addClickEvent() {
        $('.footer_progress_inner').find('.progress-bar-module').hide();
      }

      $(function () {
        addClickEvent();
      });
    } else {
      function addClickEvent() {
        $('.footer_progress_inner').find('.progress-bar-module').show();
      }

      $(function () {
        addClickEvent();
      });
    }
  }

  openMenu() {
    this.menu.enable(true, 'primerMenu');
    this.menu.open('primerMenu');
  }

}