import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Platform, MenuController } from '@ionic/angular';
import { UserModel } from 'src/app/models/user-model';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';  
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';
import { ResponseData } from 'src/app/interfaces/data';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit {

  public chapter_id = 1;
  public items: any = [];
  public items2: any = [];

  data?: ResponseData;

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    private iab: InAppBrowser,
    private platf: Platform,
    public userModel: UserModel,
    private fileOpener: FileOpener,
    private menu: MenuController,
    private dataService: DataService, 
    languageService: LanguageService,
  ) {
    let language = languageService.language;
    this.dataService.getData(language).subscribe(data => {
      this.data = data ?? undefined;
    });
  }

  ngOnInit() {
    this.chapter_id= parseInt(this.activeRoute.snapshot.paramMap.get('module_id'));
    this.dataService.getLesson(this.userModel.count(), this.chapter_id)
    .subscribe(resp => {
      console.log(resp);
      this.items = resp['data']['chapter_files'];
    })
  }

  openBrowser(url) {
    if(this.platf.is('cordova')){
      let opened = false;
      if(this.userModel.count()>0){
        let new_url = this.userModel.checkItem(url);
        if(new_url){
          opened = true;
          this.fileOpener.open(new_url, 'application/pdf').then(() => console.log('File is opened')).catch(e => console.log('Error opening file', e));
        }
      }
      if(!opened){
        this.iab.create(url, '_system');
      }
    } else {
      window.open(url, '_system');
    }
  }


  goToFinish() {
    this.route.navigate(['/finish/'+this.chapter_id]);
  }

  openMenu() {
    this.menu.enable(true, 'primerMenu');
    this.menu.open('primerMenu');
  }
}
