import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-pdf',
  templateUrl: './preview-pdf.component.html',
  styleUrls: ['./preview-pdf.component.scss'],
})
export class PreviewPdfComponent implements OnInit {

  pdf_view: SafeResourceUrl;

  @Input() pdf: string;

  constructor(
    private modalController: ModalController,
    private domSanitizer: DomSanitizer
  ) {
  }
  
  ngOnInit() {
    console.log(this.pdf);
    this.pdf_view = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pdf);
    console.log(this.pdf_view);
    
  }

  dismiss() {
    this.modalController.dismiss();
  }


}
