import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage'
import { Observable } from 'rxjs';

export interface FileItem {
    id: number,
    file_path: string,
    url: string
}

export interface Chapter {
    id: number,
    name: string,
    zone: string,
    address: string,
    latitude: number,
    longitude: number,
    reference: string
}

@Injectable()
export class UserModel {
 
    // Global
    login: boolean = false;
    token: string = null;
    expirationDate: string = null;
    role_name: string = null;
    nonuser_id: number = 0;
    push_token: string = null;
    app_name: string = 'no-package';
    app_version: string = '1.0.0';
    app_title: string = 'App';

    // User
    checkLogin: any;
    checkLoginObserver: any;

    // User Data
    user_id: number = null;
    user_phone: number = null;
    user_email: string = null;
    user_image: string = 'assets/images/user.jpg';
    user_banner_image: string = 'assets/images/portada.jpg';
    user_name: string = null;
    user_real: number = 0;
    user_address_name: string = null;
    user_zone: string = null;
    user_address: string = null;
    user_reference: string = null;
    user_latitude: boolean = false;
    latitude: number = -16.53931836;
    longitude: number = -68.0762033;


    // User
    userVerified: boolean;
    userType: string;
    customerAccess: boolean;
    businessAccess: boolean;
    driverAccess: boolean;
    date: string = null;
    offer_id: string = null;
    type_id: string = null;
    subtype_id: string = null;
    tokens: number = 0;
    cities = [{id:1,name:'La Paz'},{id:2,name:'Cochabamba'},{id:3,name:'Santa Cruz'}];
    zones = [{id:1,city_id:1,name:'La Paz'},{id:2,city_id:2,name:'Cochabamba'},{id:3,city_id:3,name:'Santa Cruz'}];
    city_id: number  = 1;
    city_name: string = 'La Paz';
    zone_id: number = 1;
    zone_name: string = 'La Paz';
    search: boolean = false;
    search_count: number = 0;
    search_count_no_type: number = 0;
    welcome: boolean = false;
    unread_notifications: number = 0;
    mapPermissions: boolean = false;
    cartItems: number = 0;
    cartTotal: number = 0;
    payment_method: string = null;
    finishOrderRedirect: boolean = false;

    // Personalizado
    languagePick: any = false; // false = es, true = en
    language: any = 'es';
    checkLanguageObserver: any;
    notification: boolean = true;
    searchPick: boolean = false;
    favoritePick: boolean = false;

    // Product Order
    items: FileItem[];
    transport_cost: number;
    total: number;
    business_id: number;
    deliver_place: Chapter;

    // Driver Status
    hasAccepted: boolean;
    activeService: boolean;

    // Parametros Generales
    checkPendingStorage: boolean = false;
    checkOfflineArrayCodes = new Array();
    parameters: Array<JSON> = new Array();
    uploadParameters = {'attendance':[],'attendance-floor':[],'attendance-exit':[],'point-request':[],'register-form':[]};
    activities: Array<JSON> = new Array();

    // Buscador General
    searchParameters: Array<JSON> = new Array();

    // Buscador Personalizado
    searchProperties: Array<JSON> = new Array();
    searchSearchs: Array<JSON> = new Array();

    constructor(
        public storage: Storage,
        //private geolocation: Geolocation,
    ){
        //alert('construyendo user model');

        /*this.geolocation.getCurrentPosition().then((resp) => {
            this.latitude = resp.coords.latitude;
            this.longitude = resp.coords.longitude;
        }).catch((error) => {
            //this.latitude = -16.52423339;
            //this.longitude = -68.11072826;
            console.log('Error getting location', error);
        });*/

        this.checkLogin = Observable.create(observer => {
          this.checkLoginObserver = observer;
        });

        //this.login = false;
        this.storage.get('login').then((val) => {
            if(val){
                this.login = val;
            } else {
                //alert('No Login');
            }
            //alert('login obtenido: ' + this.login);
        });        

        //this.token = null;
        this.storage.get('token').then((val) => {
            if(val){
                this.token = val;
            } else {
                //alert('No Token');
            }
            //alert('token obtenido: ' + this.token);
        });
        
        //this.expirationDate = null;
        this.storage.get('expirationDate').then((val) => {
            if(val){
                this.expirationDate = val;
            }
            //alert('expirationDate obtenido: ' + this.expirationDate);
        });
        
        //this.role_name = null;
        this.storage.get('role_name').then((val) => {
            if(val){
                this.role_name = val;
            }
            //alert('role_name obtenido: ' + this.role_name);
        });
        
        //this.nonuser_id = 0;
        this.storage.get('nonuser_id').then((val) => {
            if(val){
                this.nonuser_id = val;
            }
            //alert('nonuser_id obtenido: ' + this.nonuser_id);
        });

        //this.push_token = null;
        this.storage.get('push_token').then((val) => {
            if(val){
                this.push_token = val;
            }
            //alert('push_token obtenido: ' + this.push_token);
        });

        //this.app_name = 'no-package';
        this.storage.get('app_name').then((val) => {
            if(val){
                this.app_name = val;
            }
            //alert('app_name obtenido: ' + this.app_name);
        });

        //this.app_version = '1.0.0';
        this.storage.get('app_version').then((val) => {
            if(val){
                this.app_version = val;
            }
            //alert('app_version obtenido: ' + this.app_version);
        });

        //this.app_title = 'App';
        this.storage.get('app_title').then((val) => {
            if(val){
                this.app_title = val;
            }
            //alert('app_title obtenido: ' + this.app_title);
        });

        this.userVerified = false;
        this.storage.get('userVerified').then((val) => {
            if(val){
                this.userVerified = val;
            }
            //alert('userType obtenido: ' + this.userType);
        });

        this.userType = 'customer';
        this.storage.get('userType').then((val) => {
            if(val){
                this.userType = val;
            }
            //alert('userType obtenido: ' + this.userType);
        });
        this.customerAccess = false;
        this.storage.get('customerAccess').then((val) => {
            if(val){
                this.customerAccess = val;
            }
            //alert('customerAccess obtenido: ' + this.customerAccess);
        });
        this.businessAccess = false;
        this.storage.get('businessAccess').then((val) => {
            if(val){
                this.businessAccess = val;
            }
            //alert('businessAccess obtenido: ' + this.businessAccess);
        });
        this.driverAccess = false;
        this.storage.get('driverAccess').then((val) => {
            if(val){
                this.driverAccess = val;
            }
            //alert('driverAccess obtenido: ' + this.driverAccess);
        });

        // Personalizado
        this.storage.get('items').then((val) => {
            if(val){ 
                this.items = val;
            } else {
                this.items = new Array<FileItem>();
            }
        });
        this.language = Observable.create(observer => {
          this.checkLanguageObserver = observer;
        });
        this.storage.get('languagePick').then((val) => {
            if(val){ this.languagePick = val; }
        });
        this.storage.get('language').then((val) => {
            if(val){ this.language = val; }
        });

        // User Data
        this.storage.get('user_id').then((val) => {
            if(val){ this.user_id = val; }
        });
        this.storage.get('user_phone').then((val) => {
            if(val){ this.user_phone = val; }
        });
        this.storage.get('user_email').then((val) => {
            if(val){ this.user_email = val; }
        });
        this.storage.get('user_name').then((val) => {
            if(val){ this.user_name = val; }
        });
        this.storage.get('user_image').then((val) => {
            if(val){ this.user_image = val; }
        });
        this.storage.get('user_real').then((val) => {
            if(val){ this.user_real = val; }
        });
        this.storage.get('user_address_name').then((val) => {
            if(val){ this.user_address_name = val; }
        });
        this.storage.get('user_zone').then((val) => {
            if(val){ this.user_zone = val; }
        });
        this.storage.get('user_address').then((val) => {
            if(val){ this.user_address = val; }
        });
        this.storage.get('user_reference').then((val) => {
            if(val){ this.user_reference = val; }
        });
        this.storage.get('user_latitude').then((val) => {
            if(val){ this.user_latitude = val; }
        });
        this.storage.get('latitude').then((val) => {
            if(val){ this.latitude = val; }
        });
        this.storage.get('longitude').then((val) => {
            if(val){ this.longitude = val; }
        });

        this.hasAccepted = false;
        this.activeService = false;

        this.transport_cost = 0;
        this.business_id = 0;
        this.deliver_place;
    }

    successfulLogin(token, expirationDate, nonuser_id){
        this.login = true;
        this.storage.set('login', true);
        this.token = token;
        this.storage.set('token', this.token);
        this.expirationDate = expirationDate;
        this.storage.set('expirationDate', this.expirationDate);
        this.user_name = nonuser_id['name'];
        this.storage.set('user_name', this.user_name);
        this.user_email = nonuser_id['email'];
        this.storage.set('user_email', this.user_email);
        this.user_phone = nonuser_id['phone'];
        this.storage.set('user_phone', this.user_phone);
        if(!this.user_address_name){
            this.user_address_name = nonuser_id['address_name'];
            this.storage.set('user_address_name', this.user_address_name);
        }
        if(!this.user_zone){
            this.user_zone = nonuser_id['zone'];
            this.storage.set('user_zone', this.user_zone);
        }
        if(!this.user_address){
            this.user_address = nonuser_id['address'];
            this.storage.set('user_address', this.user_address);
        }
        if(!this.user_reference){
            this.user_reference = nonuser_id['reference'];
            this.storage.set('user_reference', this.user_reference);
        }
        this.user_real = nonuser_id['user_real'];
        this.storage.set('user_real', this.user_real);
        //this.checkLoginObserver.next(true);
        //alert('successfulLogin push');
    }

    addItem(file_item){
        this.items.push(file_item);
        this.storage.set('items', this.items);
    }
 
    checkItem(url: string){
        let file_path = null;
        this.items.forEach((item, index) => {
            if(item.url == url){
                file_path = item.file_path;
            }
        });
        return file_path;
    }

    clear(){
        this.items = new Array<FileItem>();
        this.storage.set('items', this.items);
    }

    count(): number {
        if(!this.items){
            return 0;
        } else {
            return this.items.length;
        }
    }
    
}