import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';
import { LanguagePopoverComponent } from './popover/popover.component';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent implements OnInit {

    language: FormControl = new FormControl(this.languageService.language ?? 'es');

    constructor(
        private dataService: DataService,
        private languageService: LanguageService,
        private popoverCtrl: PopoverController,
    ) {
        this.language.valueChanges.subscribe(data => {
            this.changeLanguage(data);
        });
    }
    
    ngOnInit() {
    }

    changeLanguage(language: 'es'|'en'|'pt' = 'es') {
        const allowedLanguages: Array<'es' | 'en' | 'pt'> = ['es', 'en', 'pt'];

        // Verificar si el valor es válido
        if (!allowedLanguages.includes(language)) {
            console.error(`Invalid language: ${language}. Defaulting to 'es'.`);
            language = 'es';
        }
        this.dataService.processGetApi(`change-locale/${language}`).subscribe(data => {
            console.log(data);
            this.languageService.language = language;
            setTimeout(() => {
                this.refresh(); 
            }, 200);
        }, error => {
            console.log(error);
        });
    }

    refresh(): void {
        window.location.reload();
    }

    async selectLanguage(event) {
        const popover = await this.popoverCtrl.create({
            component: LanguagePopoverComponent,
            event,
            cssClass: 'select__language-popover'
        });
        await popover.present();

        popover.onWillDismiss().then(data => {
            if(data.data && (data.role && data.role === 'select')) {
                this.language.patchValue(data.data);
            }
        })
    }

}