import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {

  @Input() image: string;

  silderOpts = {
    zoom: {
      maxRatio: 2
    },
    observer: true,
    observeParents: true,
  }

  constructor(
    private modalController: ModalController
  ) {
  }
  
  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
