import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ResponseData } from 'src/app/interfaces/data';
import { UserModel } from 'src/app/models/user-model';

import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  public chapter_id = 1;
  public title = null;
  public items: any = [];
  public loaded = false;
  module_id = null;
  public searchTerm: any;
  public count = 0;

  data?: ResponseData;

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    public userModel: UserModel,
    private menu: MenuController,
    private dataService: DataService, 
    languageService: LanguageService,
  ) {
    let language = languageService.language;
    this.dataService.getData(language).subscribe(data => {
      this.data = data ?? undefined;
    });
  }

  ngOnInit() {
    if(this.route.getCurrentNavigation() && this.route.getCurrentNavigation().extras && this.route.getCurrentNavigation().extras.state){
      this.searchTerm = this.route.getCurrentNavigation().extras.state.searchTerm;
      this.chapter_id= parseInt(this.activeRoute.snapshot.paramMap.get('module_id'));
      this.dataService.getSearchLesson(this.userModel.count(), this.chapter_id, this.searchTerm)
      .subscribe(resp => {
        console.log(resp);
        this.title = resp['data']['name'];
        this.items = resp['data']['chapter_sliders'];
        this.module_id = resp['data']['id'];
        this.count = resp['data']['count'];
        this.loaded = true;
      })
    } else {
      this.chapter_id= parseInt(this.activeRoute.snapshot.paramMap.get('module_id'));
      this.dataService.getLesson(this.userModel.count(), this.chapter_id)
      .subscribe(resp => {
        console.log(resp);
        this.title = resp['data']['name'];
        this.items = resp['data']['chapter_sliders'];
        this.module_id = resp['data']['id'];
        this.count = 0;
        this.loaded = true;
      })
     }
  }

  search($event) {
    this.searchTerm = $event.target.value;
    this.chapter_id= parseInt(this.activeRoute.snapshot.paramMap.get('module_id'));
    this.dataService.getSearchLesson(this.userModel.count(), this.chapter_id, this.searchTerm)
    .subscribe(resp => {
      console.log(resp);
      this.title = resp['data']['name'];
      this.items = resp['data']['chapter_sliders'];
      this.module_id = resp['data']['id'];
      this.count = resp['data']['count'];
      this.loaded = true;
    })
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

  goToIntro() {
    this.route.navigate(['/introducing/'+this.chapter_id]);
  }
  
  goToChapter(id) {
    this.route.navigate(['/modulo/'+this.chapter_id+'/'+id]);
  }

  openMenu() {
    this.menu.enable(true, 'primerMenu');
    this.menu.open('primerMenu');
  }
  
}
