import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Chapter1Component } from './chapter1/chapter1.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { PopupComponent } from './popup/popup.component';
import { FinishComponent } from './finish/finish.component';
import { IonicModule } from '@ionic/angular';
import { SelectLanguageComponentModule } from '../components/select-language-component.module';

@NgModule({
  declarations: [
    Chapter1Component,
    PopupComponent,
    FinishComponent,
    DownloadsComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    SelectLanguageComponentModule,
  ],
  exports: [
    Chapter1Component,
    PopupComponent,
    FinishComponent,
    DownloadsComponent
  ]
})
export class ChaptersModule { }
