import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ResponseData } from 'src/app/interfaces/data';
import { UserModel, FileItem } from 'src/app/models/user-model';

import { DataService } from 'src/app/services/data.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-introducing',
  templateUrl: './introducing.component.html',
  styleUrls: ['./introducing.component.scss'],
})
export class IntroducingComponent implements OnInit {

  public chapter_id = 1;
  public title = null;
  public items: any = [];
  public loaded = false;
  module_id = null;
  public searchTerm: any;

  data?: ResponseData;

  constructor(
    private route: Router,
    private activeRoute: ActivatedRoute,
    public userModel: UserModel,
    private menu: MenuController,
    private dataService: DataService, 
    languageService: LanguageService,
  ) {
    let language = languageService.language;
    this.dataService.getData(language).subscribe(data => {
      this.data = data ?? undefined;
    });
  }

  ngOnInit() {
    this.chapter_id= parseInt(this.activeRoute.snapshot.paramMap.get('module_id'));
    this.dataService.getLesson(this.userModel.count(), this.chapter_id)
    .subscribe(resp => {
      console.log(resp);
      this.title = resp['data']['name'];
      this.items = resp['data']['chapter_sliders'];
      this.module_id = resp['data']['id'];
      this.loaded = true;
    })
  }

  search($event) {
    this.searchTerm = $event.target.value;
    this.route.navigate(['/buscar/'+this.chapter_id], { state: { searchTerm: this.searchTerm } });
  }

  goToHome() {
    this.route.navigate(['/home']);
  }

  goToChapter(id) {
    this.route.navigate(['/modulo/'+this.chapter_id+'/'+id], { state: { items: this.items } });
  }

  goToSearch(id) {
    this.route.navigate(['/buscar/'+id], { state: { items: this.items } });
  }

  goToEndChapter(id) {
    this.route.navigate(['/finish/'+id], { state: { items: this.items } });
  }

  goToDownloads(id) {
    this.route.navigate(['/downloads/'+id], { state: { items: this.items } });
  }

  openMenu() {
    this.menu.enable(true, 'primerMenu');
    this.menu.open('primerMenu');
  }

}
