import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IntroducingComponent } from './pages/introducing/introducing.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ContactComponent } from './pages/contact/contact.component';
import { Chapter1Component } from './chapters/chapter1/chapter1.component';
import { FinishComponent } from './chapters/finish/finish.component';
import { DownloadsComponent } from './chapters/downloads/downloads.component';
import { SearchComponent } from './pages/search/search.component';
import { PopupComponent } from './chapters/popup/popup.component';

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'introducing/:module_id', component: IntroducingComponent },
  { path: 'creditos', component: AboutusComponent },
  { path: 'contacto', component: ContactComponent },
  { path: 'modulo/:module_id', component: IntroducingComponent },
  { path: 'modulo/:module_id/:id', component: Chapter1Component },
  { path: 'finish/:module_id', component: FinishComponent },
  { path: 'downloads/:module_id', component: DownloadsComponent },
  { path: 'buscar/:module_id', component: SearchComponent },
  { path: 'popup', component: PopupComponent },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'recursos',
    loadChildren: () => import('./pages/avatar/avatar.module').then( m => m.AvatarPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
