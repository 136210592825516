import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntroducingComponent } from './introducing/introducing.component';
import { SearchComponent } from './search/search.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { IonicModule } from '@ionic/angular';
import { SelectLanguageComponentModule } from '../components/select-language-component.module';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    IntroducingComponent,
    SearchComponent,
    ContactComponent,
    AboutusComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    SelectLanguageComponentModule,
    ComponentsModule,
  ],
  exports: [
    IntroducingComponent,
    SearchComponent,
    ContactComponent,
    AboutusComponent
  ]
})
export class PagesModule { }
